.s12wl0p4{background:var(--color-blue);padding:3px 6px;border-radius:5px;}
.u1idwrah{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:13px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:var(--u1idwrah-0);}
.m1917pqo{list-style:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:30px;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}
.f1s2rjx7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-top:auto;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:36px;padding-top:30px;}
.igw5zwh{width:16px;}
.n1dsj7ri{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:5px;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}
.m1eqa9h9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:12px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:var(--m1eqa9h9-0);}.m1eqa9h9 svg path{fill:var(--m1eqa9h9-1);}.m1eqa9h9:hover svg path{fill:var(--m1eqa9h9-2);}.m1eqa9h9:hover .pchi0mf{color:var(--m1eqa9h9-3);font-weight:var(--m1eqa9h9-4);}
.s4b9kmc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:12px;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}
.snyccpe{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.snyccpe svg{cursor:pointer;}

